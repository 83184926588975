import React, { useState, useEffect } from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Button } from 'components/Button/Button'
import {
  KBResponseWrite,
  KBResponseRead,
  defaultHelpMessage,
  personalizedHelpMessage,
  AddResponseButton,
  IUpdateOrCreateResponseRequest,
} from 'components/KnowledgeSeedEditPanel/KnowledgeSeedResponse'
import { RootState as IState } from 'store/store'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel.scss'
import { useFeatures, useSelector } from 'util/hooks'
import { Tag } from 'components/Tag/Tag'
import { uniq } from 'lodash'

import { toTitleCase } from 'util/string'
import * as api from 'api'
import classNames from 'classnames'
import { createStandardAction } from 'typesafe-actions'
import scssVariables from 'scss/_variables.scss'
import { Spinner } from 'components/Spinner/Spinner'
import { CenteredLoader } from 'components/Loader/Loader'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { Alert } from 'components/Alert/Alert'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { LabeledTextArea } from 'components/LabeledInput/LabeledInput'
import { UnderstandingQuestionSummaryIcon } from 'components/Icons/UnderstandingQuestionSummaryIcon/UnderstandingQuestionSummaryIcon'
import {
  getHasContactAttribute,
  useParseAnswer,
} from 'components/DraftEditor/draftUtils'
import { CollapsibleSection } from 'components/CollapsibleSection/CollapsibleSection'
import format from 'date-fns/format'
import { getAttributeByIdMapping } from 'store/personalization/selectors'
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
} from 'react-beautiful-dnd'
import { RefetchingOverlay } from 'components/RefetchingOverlay/RefetchingOverlay'
import { IAttributesById } from 'store/personalization/institutionAttributes/selectors'
import { ICampaignScriptStep } from 'store/campaign-scripts/reducer'
import { AnimateRefs } from 'components/AnimateRefs/AnimateRefs'
import { IFailure, ILoading, isSuccess, ISuccess } from 'store/webdata'
import { connect } from 'react-redux'

import { AxiosError } from 'typings/axios'
import { detailOrDefault } from 'api/http'
import {
  AddFuzzyQuestionButton,
  DEFAULT_QUESTION_COUNT_SHOWN,
  IQuestionMetadata,
  useKnowledgeSeed,
} from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel'
import { TrashCanIcon } from 'components/Icons/TrashCanIcon/TrashCanIcon'
import 'react-loading-skeleton/dist/skeleton.css'
import { AIAction, AIMenu, MenuSize } from 'components/AIMenu/AIMenu'
import { isLeft } from 'fp-ts/lib/Either'
import { OnOffToggleSwitch } from 'components/LabeledToggle/LabeledToggle'
import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import Tooltip from 'components/Tooltip/Tooltip'
import { getInstitutionTimeZone } from 'store/triage/profile/selectors'
import moment from 'moment-timezone'

import { LoadingSkeleton } from 'components/LoadingSkeleton/LoadingSkeleton'

interface ILastEditedByProps {
  name?: string | null
  date?: string | null
}

export function LastEditedBy({ name, date }: ILastEditedByProps) {
  if (name == null || date == null) {
    return <></>
  }
  const displayDate = format(new Date(date), 'MMM d, y')
  // This should look like 'Last edited by AdmitHub Staff, Jan 12, 2019'.
  return (
    <p className="text-muted caption m-0" title={displayDate}>
      Last edited by {name}, {displayDate}
    </p>
  )
}

interface IAnswerProps {
  readonly lastModifiedByName?: string
  readonly lastModifiedAt?: string
  readonly approved: boolean
  readonly answer?: string
  readonly pendingAnswer?: string
  readonly onApprove?: () => void
  readonly onUnapprove?: () => void
  readonly onDelete?: () => void
  readonly onSave: (
    params: IUpdateOrCreateResponseRequest,
    moveToTop?: boolean
  ) => void
  readonly onToggleEditor?: (show: boolean) => void
  readonly className?: string
  readonly answerId: number
  readonly contactFilter?: {
    id: number
    name: string
  }
  readonly disableApprovalMenu?: boolean
  readonly actionInProgress: boolean
  readonly editing: boolean
  readonly dialogId: string | null
  readonly dialogName: string | null
  readonly workflowSteps: ICampaignScriptStep[]
  readonly dragHandleProps?: DraggableProvidedDragHandleProps
  readonly isDragging?: boolean
  readonly summaryQuestion: string
  readonly understandingId: string
}

export function Answer({
  answerId,
  approved,
  lastModifiedByName,
  lastModifiedAt,
  answer,
  pendingAnswer,
  onApprove,
  onUnapprove,
  onDelete,
  onSave,
  onToggleEditor,
  className,
  contactFilter,
  disableApprovalMenu = false,
  actionInProgress,
  editing,
  dialogId,
  dialogName,
  workflowSteps,
  dragHandleProps,
  isDragging,
  summaryQuestion,
  understandingId,
}: IAnswerProps) {
  const [editingAnswer, setEditingAnswer] = useState(answer)
  const [editingAnswerId, setEditingAnswerId] = useState<undefined | number>(
    undefined
  )
  const [editingApproved, setEditingApproved] = useState(approved)
  const [selectedFilter, setSelectedFilter] = useState(contactFilter?.id)

  // undefined = unknown, null = known to not be embedded, string = date embedded
  const [lastEmbeddedAt, setLastEmbeddedAt] = useState<
    undefined | string | null
  >(undefined)

  const mappingResults = useSelector(getAttributeByIdMapping)
  const mapping = React.useMemo(
    () =>
      isSuccess(mappingResults)
        ? mappingResults.data
        : { institution: {}, contact: {}, toplevel: {} },
    [mappingResults]
  )

  useEffect(() => {
    setEditingAnswer(answer)
  }, [answer, editing])
  useEffect(() => {
    setEditingApproved(approved)
  }, [approved, editing])
  useEffect(() => {
    setSelectedFilter(contactFilter?.id)
  }, [contactFilter?.id, editing])

  useEffect(() => {
    setEditingAnswerId(undefined)
  }, [contactFilter?.id, answer, approved])

  useEffect(() => {
    api.generativeTextService.embedding
      .getAnswerEmbeddingMetadata({
        answerId,
      })
      .then(res => {
        if (isLeft(res)) {
          toast('Failed to fetch answer embedding status', { type: 'error' })
          return
        }

        setLastEmbeddedAt(res.right.answer_embedding_metadata?.modified || null)
      })
  }, [answerId])

  const {
    selectedContactAttributes: selectedContactAttributesAndFields,
  } = useParseAnswer(editingAnswer)

  function handleCancel() {
    onToggleEditor?.(false)
    setEditingAnswerId(undefined)
  }

  function handleDelete(id: number | undefined) {
    if (id) {
      setEditingAnswerId(id)
      onDelete?.()
    }
  }

  function handleSave(request: IUpdateOrCreateResponseRequest) {
    const wasPersonalized = isAnswerPersonalized(
      { contactFilter, answer },
      mapping
    )
    const isPersonalized = isAnswerPersonalized(
      {
        contactFilter: request.contactFilter
          ? { id: request.contactFilter, name: '' }
          : undefined,
        answer: request.answer,
      },
      mapping
    )
    // If the user was editing an unpersonalized answer, but then personalized
    // it, we should move it to the top of the personalized list.
    const moveToTop = !wasPersonalized && isPersonalized
    setEditingAnswerId(request.id)
    onSave(request, moveToTop)
  }

  const audience = selectedContactAttributesAndFields?.length ? (
    <>
      {selectedContactAttributesAndFields &&
        // Deduplicate all selected contact attributes and topLevelFields
        // this way, if a user includes the same attr or field more than once,
        // only one line for that audience item will show
        uniq(selectedContactAttributesAndFields).map((attr, index) => (
          <span key={attr}>
            <span>{`Contacts with information for ${attr}`}</span>
            {index !== selectedContactAttributesAndFields.length - 1 && <br />}
          </span>
        ))}
    </>
  ) : (
    undefined
  )

  if (editing && answerId === editingAnswerId) {
    return (
      <div
        className={classNames(
          { 'shadow-border': isDragging },
          { 'opacity-50': actionInProgress && answerId === editingAnswerId }
        )}>
        <KBResponseWrite
          answerId={answerId}
          dialogId={dialogId}
          dialogName={dialogName}
          initialAnswer={editingAnswer}
          approved={editingApproved}
          selectedFilter={selectedFilter}
          workflowSteps={workflowSteps}
          onSave={handleSave}
          onCancel={handleCancel}
          onDelete={onDelete ? () => handleDelete(answerId) : undefined}
          lastModifiedByName={lastModifiedByName}
          lastModifiedAt={lastModifiedAt}
          lastEmbeddedAt={lastEmbeddedAt}
          hideApproval={disableApprovalMenu}
          dragHandleProps={dragHandleProps}
          genAIProps={{ summaryQuestion, understandingId }}
        />
      </div>
    )
  }
  return (
    <div
      onClick={() => {
        setEditingAnswerId(answerId)
        onToggleEditor?.(true)
      }}
      className={classNames(
        'knowledge-answer-group-show-ui-on-hover',
        'pointer',
        { 'shadow-border': isDragging },
        { 'opacity-50': actionInProgress },
        className
      )}>
      <KBResponseRead
        answer={pendingAnswer || editingAnswer}
        dialogId={dialogId}
        dialogName={dialogName}
        workflowSteps={workflowSteps}
        onUnapprove={() => {
          setEditingAnswerId(answerId)
          setEditingApproved(false)
          onUnapprove?.()
        }}
        onApprove={() => {
          setEditingAnswerId(answerId)
          setEditingApproved(true)
          onApprove?.()
        }}
        onDelete={onDelete ? () => handleDelete(answerId) : undefined}
        approved={editingApproved}
        lastModifiedByName={lastModifiedByName}
        lastModifiedAt={lastModifiedAt}
        lastEmbeddedAt={lastEmbeddedAt}
        hideApproval={disableApprovalMenu}
        audience={audience}
        contactFilterName={contactFilter?.name}
        dragHandleProps={dragHandleProps}
      />
    </div>
  )
}

interface ISuggestedAnswersDropDownProps {
  readonly suggestedAnswers: ReadonlyArray<{
    id: number
    answer: string
    modified: string
  }>
  copyAllAnswers: () => void
  showFallbackResponseTips?: boolean
  copyAnswer: (params: { answerId: number; approve?: boolean }) => void
  readonly defaultExpanded: boolean
  innerRef?: React.RefObject<HTMLDivElement>
}

const SuggestedAnswer = ({
  answer,
  lastEditedAt,
  onCopy,
}: {
  answer: string
  lastEditedAt: string
  onCopy: () => void
}) => {
  return (
    <div className="d-flex flex-column pt-2 pb-1 pl-4 pr-3 move-suggested-response-content-left">
      <div className="mainstay-body-caption text-mainstay-dark-blue-80">
        Suggested Response
      </div>
      <div className="mainstay-body-paragraph pb-2">{answer}</div>
      <div className="d-flex align-items-center justify-content-between">
        <div
          onClick={onCopy}
          className="mainstay-body-paragraph text-link-primary hover-text-link-primary-hover pb-2 pointer">
          Add this response
        </div>
        <LastEditedBy name="Mainstay Staff" date={lastEditedAt} />
      </div>
    </div>
  )
}

export function SuggestedAnswersDropdown({
  suggestedAnswers,
  copyAllAnswers,
  copyAnswer,
  defaultExpanded,
  showFallbackResponseTips,
}: Omit<ISuggestedAnswersDropDownProps, 'innerRef'>) {
  const [expanded, setExpanded] = useState(false)
  const [useExpandedClass, setUseExpandedClass] = useState(false)

  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(defaultExpanded)
    }
  }, [defaultExpanded])

  function toggleExpanded() {
    setExpanded(s => !s)
  }

  useEffect(() => {
    setUseExpandedClass(expanded)
    setExpanded(expanded)
  }, [expanded])

  return (
    <CollapsibleSection
      className={classNames('suggested-answers-border position-relative pt-3', {
        expanded: useExpandedClass,
      })}
      onClick={toggleExpanded}
      overrideMargin={true}
      startExpanded={expanded}
      alignItems="start"
      chevronClassName="absolute-chevron"
      title={
        <div className="d-flex flex-column pb-3">
          <div className="mainstay-header-h5">Not sure what to write?</div>
          <div className="mainstay-body-paragraph text-mainstay-dark-blue-80">
            Check out these suggested responses
          </div>
        </div>
      }>
      <div className="mainstay-header-h5 suggested-answers-border-expanded">
        {showFallbackResponseTips && <FallbackResponseTips />}

        {suggestedAnswers.map((x, ix) => (
          <div
            className={classNames({
              'border-bottom': ix < suggestedAnswers.length - 1,
            })}
            key={x.id}>
            <SuggestedAnswer
              answer={x.answer}
              onCopy={() => copyAnswer({ answerId: x.id })}
              lastEditedAt={x.modified}
            />
          </div>
        ))}
      </div>
      {suggestedAnswers.length > 1 ? (
        <Button
          className="mt-3"
          color="primary"
          outlined
          onClick={copyAllAnswers}>
          Copy All to my Account
        </Button>
      ) : null}
    </CollapsibleSection>
  )
}

const FallbackResponseTips = () => {
  return (
    <div className="caption d-flex align-items-center justify-content-start py-2">
      <AHIcon name="help" className="ah-help-icon" />
      <span className="ml-2">
        Learn how to write{' '}
        <a href="https://support.mainstay.com/hc/en-us/articles/360044853551">
          helpful Fallback Responses
        </a>
      </span>
    </div>
  )
}

interface IAnswerResponseData {
  id: number
  answer?: string
  pendingAnswer?: string
  dialogId: string | null
  dialogName: string | null
  filter?: number
  approved: boolean
  modified: string
  order: number
  lastEditedBy?: {
    id: string
    name: string
  }
  contactFilter?: {
    id: number
    name: string
  }
  workflowSteps: ICampaignScriptStep[]
}

export const updateGlobalQuestion = createStandardAction(
  '@@mascot/knowledge/understandings/updateGlobalQuestion'
)<{
  readonly id: number
  readonly enabled: boolean
}>()
export const updateInstitutionQuestion = createStandardAction(
  '@@mascot/knowledge/understandings/updateInstitutionQuestion'
)<{
  readonly id: number
  readonly question: string
}>()
export const deleteInstitutionQuestion = createStandardAction(
  '@@mascot/knowledge/understandings/deleteInstitutionQuestion'
)<{ readonly id: number }>()
export const addGeneratedInstitutionQuestions = createStandardAction(
  '@@mascot/knowledge/understandings/addGeneratedInstitutionQuestions'
)<
  {
    readonly id: number
    readonly enabled: boolean
  }[]
>()

type knowledgeSeedStateGlobalQuestions = ReadonlyArray<{
  readonly id: number
  readonly enabled: boolean
  readonly question: string
}>

const FuzzyQuestionCancelButton = ({ onClick }: { onClick: () => void }) => (
  <Button className="ml-1 fuzzy-question-cancel-button" onClick={onClick}>
    Cancel
  </Button>
)

export interface IKnowledgeSeedEditPanelState {
  readonly view: 'loading' | 'failure' | 'success'
  readonly institutionAbbreviation: string | null
  readonly sampleQuestion: string
  readonly category: string
  readonly subCategory: string
  answers: ReadonlyArray<IAnswerResponseData>
  readonly suggestedAnswers: ReadonlyArray<{
    readonly id: number
    readonly answer: string
    readonly modified: string
  }>
  readonly institutionQuestions: ReadonlyArray<{
    id: number
    question: string
  }>
  readonly topic: string
  readonly enabledGlobalQuestions: knowledgeSeedStateGlobalQuestions
  readonly disabledGlobalQuestions: knowledgeSeedStateGlobalQuestions
  readonly actionInProgress: boolean
  readonly answersBeingEdited: number[]
  readonly answersPendingRequest: number[]
  readonly reordering: boolean
  readonly addingDefaultResponse: boolean
  readonly addingPersonalizedResponse: boolean
  readonly enableAnimation: boolean
  readonly newAnswerUUID?: string
  readonly newAnswerRef?: React.RefObject<HTMLDivElement>
  readonly answerPendingCreation?: {
    data: IAnswerProps
    addedAsPersonalized?: boolean
  }
  animationUUIDMap: { [key: number]: string } // Maps the UUID of newly created answers to their subsequent answer id
}

function toastWarning(message: string) {
  toast(message, { type: 'error' })
}

export const LoadingFuzzyQuestion = () => (
  <div className="d-flex align-items-center pb-3">
    <div className="fuzzy-question-container generated-fuzzy-question loading-fuzzy-question">
      <div className="fs-0_8rem">Organization-specific</div>
      <LoadingSkeleton />
    </div>
    <div className="ml-2">
      <TrashCanIcon muted={true} />
    </div>
  </div>
)

function OrgQuestionEditor({
  id: questionId,
  question: initialQuestion,
  showBorder,
  updateQuestion,
  deleteQuestion,
  metadata,
  created,
  modified,
}: {
  showBorder?: boolean
  question: string
  id: number
  updateQuestion: (q: string) => void
  deleteQuestion: () => void
  metadata?: IQuestionMetadata
  created?: string
  modified?: string
}) {
  const [question, setQuestion] = React.useState(initialQuestion)
  const [editorOpen, setEditorOpen] = React.useState(false)
  const [saving, setSaving] = React.useState(false)
  React.useEffect(() => {
    setQuestion(initialQuestion)
  }, [initialQuestion])
  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setEditorOpen(true)
    setQuestion(e.target.value)
  }
  function handleCancel() {
    setEditorOpen(false)
    setQuestion(initialQuestion)
  }
  function handleSave() {
    setSaving(true)
    api
      .updateOrganizationQuestion({ questionId, question })
      .then(res => {
        updateQuestion(res.data.question)
        setSaving(false)
        setEditorOpen(false)
      })
      .catch((error: AxiosError) => {
        toastWarning(detailOrDefault(error, 'Problem updating question.'))
        setSaving(false)
      })
  }

  return (
    <div className="d-flex flex-column pb-3">
      <div className="d-flex align-items-center">
        <LabeledTextArea
          label="Organization-specific"
          className={classNames(
            'mb-0 d-flex align-items-center fuzzy-question-container',
            { 'generated-fuzzy-question': showBorder }
          )}
          rows={2}
          textAreaClassName="w-100 h-100"
          overrideTextAreaMargin={true}
          value={question}
          onChange={handleChange}
        />
        <div onClick={deleteQuestion} className="ml-2 pointer move-trash-can">
          <TrashCanIcon />
        </div>
        <AdmithubOnly>
          <QuestionEmbeddingStatus
            metadata={metadata}
            questionCreated={created}
            questionModified={modified}
            questionId={questionId}
          />
        </AdmithubOnly>
      </div>
      {editorOpen && (
        <div className="pt-2 d-flex align-items-center justify-content-start">
          <Button color="primary" onClick={handleSave} loading={saving}>
            Save
          </Button>
          <FuzzyQuestionCancelButton onClick={handleCancel} />
        </div>
      )}
    </div>
  )
}

function OrgSpecificQuestionsSection({
  questions,
  addQuestion,
  deleteQuestion,
  updateOrgQuestion,
  understandingId,
  loadingGenerated,
}: {
  readonly questions: ReadonlyArray<{
    readonly id: number
    readonly question: string
    readonly created?: string
    readonly modified?: string
    readonly generated?: boolean
    readonly metadata?: IQuestionMetadata
  }>
  readonly addQuestion: (props: { id: number; question: string }) => void
  readonly deleteQuestion: (id: number) => void
  readonly updateOrgQuestion: (props: { id: number; question: string }) => void
  readonly understandingId: string
  readonly loadingGenerated?: boolean
}) {
  const [newQuestion, setNewQuestion] = React.useState('')
  const [addingQuestionState, setAddingQuestionState] = React.useState<
    'collapsed' | 'creating' | 'submitting'
  >('collapsed')
  const [isExpanded, setIsExpanded] = React.useState(false)
  React.useEffect(() => {
    if (loadingGenerated && !isExpanded) {
      setIsExpanded(true)
    }
  }, [loadingGenerated, isExpanded, setIsExpanded])
  const listEnd = React.useRef<HTMLDivElement | null>(null)

  const handleAddQuestion = (e: React.FormEvent) => {
    e.preventDefault()
    if (addingQuestionState === 'submitting') {
      return
    }
    setAddingQuestionState('submitting')
    api
      .addOrganizationQuestion({ understandingId, question: newQuestion })
      .then(res => {
        setIsExpanded(true)
        addQuestion({ id: res.data.id, question: res.data.question })
        setNewQuestion('')
        setAddingQuestionState('collapsed')
        listEnd.current?.scrollIntoView()
      })
      .catch((error: AxiosError) => {
        toastWarning(detailOrDefault(error, 'Problem adding question.'))
        setAddingQuestionState('creating')
      })
  }

  const openAddFuzzyQuestion = () => {
    setAddingQuestionState('creating')
  }

  const handleCreateFuzzyQuestionCancel = () => {
    setAddingQuestionState('collapsed')
    setNewQuestion('')
  }

  const handleShowMoreToggle = () => setIsExpanded(s => !s)

  const handleNewFuzzyQuestionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNewQuestion(e.target.value)
  }

  const qs = orderBy(questions, ['id'])
  const questionsShown = isExpanded
    ? qs
    : qs.slice(0, DEFAULT_QUESTION_COUNT_SHOWN)
  return (
    <>
      <div className="mainstay-header-h5 text-mainstay-dark-blue-80 mb-2">
        Your Organization's Questions
      </div>

      {questionsShown.length > 0 ? (
        questionsShown.map(q => (
          <OrgQuestionEditor
            showBorder={q.generated}
            key={q.id}
            updateQuestion={updatedQuestion =>
              updateOrgQuestion({ id: q.id, question: updatedQuestion })
            }
            deleteQuestion={() => deleteQuestion(q.id)}
            question={q.question}
            id={q.id}
            metadata={q.metadata}
            created={q.created}
            modified={q.modified}
          />
        ))
      ) : (
        <p className="text-center caption text-muted">
          No organization-specific questions added yet.
        </p>
      )}

      {/* Note(sbdchd): div that we can scroll to*/}
      <div ref={listEnd} />
      {!loadingGenerated && (
        <ExpandToggleButton
          isExpanded={isExpanded}
          onToggle={handleShowMoreToggle}
          questions={questions}
        />
      )}
      {loadingGenerated &&
        Array.from(Array(10)).map((i: number) => (
          <LoadingFuzzyQuestion key={i} />
        ))}

      {addingQuestionState !== 'collapsed' ? (
        <form onSubmit={handleAddQuestion}>
          <LabeledTextArea
            className="fuzzy-question-container mb-0"
            textAreaClassName="h-100 w-100"
            overrideTextAreaMargin={true}
            label="Organization-specific"
            value={newQuestion}
            autoFocus
            rows={2}
            onChange={handleNewFuzzyQuestionChange}
          />
          <div className="d-flex align-items-center justify-content-start mt-2 mb-3">
            <Button
              color="primary"
              type="submit"
              loading={addingQuestionState === 'submitting'}
              loadingText="Submitting...">
              Create Question
            </Button>

            <FuzzyQuestionCancelButton
              onClick={handleCreateFuzzyQuestionCancel}
            />
          </div>
        </form>
      ) : (
        <AddFuzzyQuestionButton
          onClick={openAddFuzzyQuestion}
          disabled={addingQuestionState !== 'collapsed'}
        />
      )}
    </>
  )
}

function ExpandToggleButton({
  isExpanded,
  questions,
  onToggle,
}: {
  readonly isExpanded: boolean
  readonly questions: ReadonlyArray<unknown>
  readonly onToggle: () => void
}) {
  if (questions.length <= DEFAULT_QUESTION_COUNT_SHOWN) {
    return null
  }
  const buttonText = isExpanded
    ? 'Show fewer questions'
    : `Show ${questions.length - DEFAULT_QUESTION_COUNT_SHOWN} more questions`
  const icon = isExpanded ? (
    <AHIcon name="expand_less" />
  ) : (
    <AHIcon name="expand_more" />
  )
  return (
    <div className="d-flex justify-content-center">
      <Button
        color="link"
        className="pt-0 mb-3 d-flex align-items-center text-decoration-none text-muted fs-13px"
        onClick={onToggle}>
        {buttonText}
        {icon}
      </Button>
    </div>
  )
}

function FuzzyQuestionListSection({
  title,
  questions,
  label,
  updateQuestion,
  global,
}: {
  readonly title?: string
  readonly label: string
  readonly questions: ReadonlyArray<{
    readonly id: number
    readonly question: string
    readonly created?: string
    readonly modified?: string
    readonly enabled: boolean
    readonly metadata?: IQuestionMetadata
  }>
  readonly updateQuestion: (id: number, enabled: boolean) => void
  readonly global?: boolean
}) {
  const [isExpanded, setIsExpanded] = React.useState(false)

  const handleExpandToggle = () => setIsExpanded(s => !s)

  const questionsShown = isExpanded
    ? questions
    : questions.slice(0, DEFAULT_QUESTION_COUNT_SHOWN)
  return (
    <>
      {title && <p className="caption mb-1">{title}</p>}
      {questionsShown.map(q => {
        if (global) {
          return (
            <>
              <div key={q.id} className="d-flex flex-column">
                <p className="caption mb-1">{label}</p>
                <div className="d-flex justify-content-between">
                  <p className="mb-1">{q.question}</p>
                  <div>
                    <OnOffToggleSwitch
                      size="sm"
                      checked={q.enabled}
                      className="ml-2"
                      onChange={isEnabled => {
                        updateQuestion(q.id, isEnabled)
                      }}
                    />
                    <AdmithubOnly>
                      <QuestionEmbeddingStatus
                        metadata={q.metadata}
                        questionCreated={q.created}
                        questionModified={q.modified}
                        questionId={q.id}
                        globalQuestionEnabled={q.enabled}
                      />
                    </AdmithubOnly>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
            </>
          )
        }
        return (
          <LabeledTextArea
            key={q.id}
            label={label}
            className="d-flex align-items-center mb-1"
            readOnly
            rows={2}
            value={q.question}>
            <OnOffToggleSwitch
              size="sm"
              checked={q.enabled}
              className="ml-2 mb-3"
              onChange={isEnabled => {
                updateQuestion(q.id, isEnabled)
              }}
            />
            <AdmithubOnly>
              <QuestionEmbeddingStatus
                metadata={q.metadata}
                questionCreated={q.created}
                questionModified={q.modified}
                questionId={q.id}
              />
            </AdmithubOnly>
          </LabeledTextArea>
        )
      })}

      <ExpandToggleButton
        onToggle={handleExpandToggle}
        questions={questions}
        isExpanded={isExpanded}
      />
    </>
  )
}

const isAnswerPersonalized = (
  answer: Pick<IAnswerResponseData, 'contactFilter' | 'answer'>,
  mapping: IAttributesById
) => {
  const hasContactFilter = !!answer.contactFilter?.id
  const hasContactAttributes =
    answer.answer && getHasContactAttribute(answer.answer, mapping)
  return hasContactFilter || !!hasContactAttributes
}

interface IKnowledgeSeedEditPanelContentProps {
  understandingId: string
  onClose: () => void
  mappingResults:
    | ILoading
    | IFailure<IAttributesById>
    | ISuccess<IAttributesById>
    | undefined
  matchedQuestion?: string | null
  isNewlyCreated?: boolean

  /**
   * If set the panel will collapse all other sections besides the "Questions" section. Then this question text will be shown as a potential new question to be added.
   */
  potentialNewQuestion?: string

  panelRef: React.RefObject<HTMLDivElement>
  contentClassName?: string
}

const getSortedPartitionedAnswers = (
  answers: readonly IAnswerResponseData[],
  mapping: IAttributesById
): [IAnswerResponseData[], IAnswerResponseData[]] => {
  const [personalizedAnswers, unpersonalizedAnswers] = partition(answers, x =>
    isAnswerPersonalized(x, mapping)
  )

  return [
    orderBy(personalizedAnswers, ['order', 'created'], ['desc', 'asc']),
    orderBy(unpersonalizedAnswers, ['order', 'created'], ['desc', 'asc']),
  ]
}

export function UnconnectedKnowledgeSeedEditPanelContent({
  understandingId,
  onClose,
  mappingResults,
  potentialNewQuestion,
  isNewlyCreated,
  panelRef,
  matchedQuestion,
  contentClassName,
}: IKnowledgeSeedEditPanelContentProps) {
  const {
    state,
    loadingDrawer,
    loadingGeneratedQuestions,
    ...actions
  } = useKnowledgeSeed({
    understandingId,
    onClose,
  })

  const showNoApprovedAnswerWarning =
    state.answers.findIndex(x => x.approved === true) < 0

  const mapping = React.useMemo(
    () =>
      isSuccess(mappingResults)
        ? mappingResults.data
        : { institution: {}, contact: {}, toplevel: {} },
    [mappingResults]
  )

  const [
    sortedPersonalizedAnswers,
    unpersonalizedAnswers,
  ] = getSortedPartitionedAnswers(state.answers, mapping)

  /**
   * Starts true if the {@link potentialNewQuestion} property was passed.
   * Once the potential new question has been added this will be set to false.
   */
  const [
    showPotentialNewQuestionUI,
    setShowPotentialNewQuestionUI,
  ] = React.useState(potentialNewQuestion !== undefined)

  /**
   * The text box value in the Questions > New Potential Question UI.
   * This UI shows up if the {@link potentialNewQuestion} property is passed.
   * A seperate state variable is needed so the input form is a controlled input.
   */
  const [
    potentialNewQuestionFormValue,
    setPotentialNewQuestionFormValue,
  ] = React.useState(potentialNewQuestion)

  /**
   * If the {@link potentialNewQuestion} property is passed all content in the Questions section, except for the New Potential Question UI,
   * is hidden. This state variable controls if this content is hidden. True = hidden, false = not hidden.
   * The "Show All Questions" button will set this to false and show all the rest of the content.
   */
  const [
    potentialNewQuestionOtherHidden,
    setPotentialNewQuestionOtherHidden,
  ] = React.useState(potentialNewQuestion !== undefined)

  /**
   * Indicates that the potential new question "+ Add" button should show "Loading" because the potential new question is being added via an ongoing API call.
   */
  const [
    potentialNewQuestionCreateLoading,
    setPotentialNewQuestionCreateLoading,
  ] = React.useState(false)

  /**
   * Called when the user clicks the "+ Add" button in the Questions > New Potential Question UI.
   * The question to add is stored in {@link potentialNewQuestionFormValue}
   */
  const handleAddNewPotentialQuestion = () => {
    if (!potentialNewQuestionFormValue) {
      return
    }

    setPotentialNewQuestionCreateLoading(true)
    api
      .addOrganizationQuestion({
        understandingId,
        question: potentialNewQuestionFormValue,
      })
      .then(res => {
        toast.success('Successfully added new Fuzzy Question')
        setShowPotentialNewQuestionUI(false)
        setPotentialNewQuestionCreateLoading(false)
        actions.updateInstitutionQuestion({
          id: res.data.id,
          question: res.data.question,
        })
      })
      .catch(() => {
        toast.error('An error occurred while adding the new Fuzzy Question')
        setPotentialNewQuestionCreateLoading(false)
      })
  }

  // Creating a bunch of Refs for use in KB Panel Answer Animations
  const [answerRefs, setAnswerRefs] = React.useState<{
    [key: string]: React.RefObject<HTMLDivElement>
    [key: number]: React.RefObject<HTMLDivElement>
  }>({})
  const animationRefs = {
    ...answerRefs,
    defaultResponseButton: React.useRef<HTMLButtonElement>(null),
    personalResponseButton: React.useRef<HTMLButtonElement>(null),
    personalizedTitleRef: React.useRef<HTMLDivElement>(null),
    hrRef: React.useRef<HTMLHRElement>(null),
    otherPanelContent: React.useRef<HTMLDivElement>(null),
    suggestedAnswersRef: React.useRef<HTMLDivElement>(null),
    personalizedDragCopyText: React.useRef<HTMLDivElement>(null),
  }

  const { FeaturesType, hasFeature } = useFeatures()

  React.useEffect(() => {
    const newAnswerRefs = { ...answerRefs }
    let updateAnswerRefs = false
    state.answers.map(ans => {
      const animationKey = state.animationUUIDMap[ans.id] || ans.id
      if (!answerRefs[animationKey]) {
        updateAnswerRefs = true
        newAnswerRefs[animationKey] =
          state.animationUUIDMap[ans.id] && state.newAnswerRef
            ? state.newAnswerRef
            : React.createRef<HTMLDivElement>()
      }
    })
    if (
      state.newAnswerUUID &&
      state.newAnswerRef &&
      !newAnswerRefs[state.newAnswerUUID]
    ) {
      // If the user is creating a new answer but it hasn't been mapped to
      // answers yet, we still need to keep track of the ref.
      newAnswerRefs[state.newAnswerUUID] = state.newAnswerRef
      updateAnswerRefs = true
    }
    if (updateAnswerRefs) {
      setAnswerRefs(newAnswerRefs)
    }
  }, [
    state.answers,
    answerRefs,
    state.animationUUIDMap,
    state.newAnswerRef,
    state.newAnswerUUID,
  ])

  function handleCreation(
    request: IUpdateOrCreateResponseRequest,
    moveToTop?: boolean,
    addedAsPersonalized?: boolean
  ) {
    actions.handleAnswerCreation(request, moveToTop, addedAsPersonalized)
  }

  const [generateAutoFuzzyQuestions] = React.useState(
    () => actions.addGeneratedInstitutionQuestions
  )
  React.useEffect(() => {
    if (
      isNewlyCreated &&
      hasFeature(FeaturesType.GENERATIVE_AI_AUTOMATIC_FUZZY_QUESTIONS)
    ) {
      generateAutoFuzzyQuestions()
    }
  }, [
    isNewlyCreated,
    hasFeature,
    FeaturesType.GENERATIVE_AI_AUTOMATIC_FUZZY_QUESTIONS,
    generateAutoFuzzyQuestions,
  ])

  const mapAnswerToComponent = (
    answer: typeof state.answers[0],
    dragHandleProps?: DraggableProvidedDragHandleProps,
    isDragging?: boolean
  ) => {
    const key = state.animationUUIDMap[answer.id] || answer.id
    return (
      <div ref={answerRefs[key]} className="mt-3">
        <Answer
          key={key}
          answerId={answer.id}
          summaryQuestion={state.sampleQuestion}
          understandingId={understandingId}
          dialogId={answer.dialogId}
          dialogName={answer.dialogName}
          answer={answer.answer}
          pendingAnswer={answer.pendingAnswer}
          approved={answer.approved}
          workflowSteps={answer.workflowSteps}
          lastModifiedByName={answer.lastEditedBy?.name}
          lastModifiedAt={answer.modified}
          contactFilter={answer.contactFilter}
          onApprove={() =>
            actions.handleAnswerApproval({
              answerId: answer.id,
              approved: true,
            })
          }
          onUnapprove={() =>
            actions.handleAnswerApproval({
              answerId: answer.id,
              approved: false,
            })
          }
          onDelete={() => actions.handleAnswerDelete({ answerId: answer.id })}
          onToggleEditor={(editing: boolean) =>
            actions.handleShowEditor({ editing, answerId: answer.id })
          }
          onSave={actions.handleUpdateAnswer}
          actionInProgress={state.answersPendingRequest.includes(answer.id)}
          editing={state.answersBeingEdited.includes(answer.id)}
          dragHandleProps={dragHandleProps}
          isDragging={isDragging}
        />
      </div>
    )
  }

  return (
    <>
      {state.view === 'loading' ? (
        <CenteredLoader className="h-100" />
      ) : state.view === 'failure' ? (
        <div className="d-flex align-items-center justify-content-center h-100">
          <p className="text-muted text-center">
            Failed to load understanding
            <br />
            You may not have permission to view this understanding in the
            current institution
          </p>
        </div>
      ) : (
        <div className={contentClassName || 'px-2'}>
          {showNoApprovedAnswerWarning ? (
            <Alert
              type="danger"
              className="mt-2 border-mainstay-error-300 bg-mainstay-error-50 text-mainstay-error-700 px-3 no-approved-answer-container">
              <div className="d-flex align-items-start">
                <AHIcon name="info_outline" className="mr-3 mt-1" />
                <div className="d-flex flex-column no-approved-answer-content">
                  <div className="mainstay-header-h4">No approved answer</div>
                  <div className="mainstay-body-paragraph">
                    Your bot will not be able to answer this question. Add a
                    default answer and mark it as approved so the bot can
                    respond.
                  </div>
                </div>
              </div>
            </Alert>
          ) : null}
          <div className="my-2 mainstay-body-paragraph text-mainstay-dark-blue-80">
            {toTitleCase(`${state.category} / ${state.subCategory}`)}
          </div>
          <div className="d-inline-flex">
            <UnderstandingQuestionSummaryIcon className="mr-2 mt-1" />
            <div className="mainstay-header-h4 text-mainstay-dark-blue-80">
              {state.sampleQuestion}
            </div>
          </div>
          <AnimateRefs
            refs={animationRefs}
            scrollOffsetRef={panelRef}
            enableAnimation={state.enableAnimation}>
            <>
              <CollapsibleSection
                title="Default Responses"
                info={defaultHelpMessage}
                startExpanded={potentialNewQuestion === undefined}
                headerChildren={
                  <Tag color="blue-grey-050">
                    {unpersonalizedAnswers.length}
                  </Tag>
                }>
                {unpersonalizedAnswers.map(x => mapAnswerToComponent(x))}
                {state.addingDefaultResponse ? (
                  <div ref={state.newAnswerRef}>
                    {state.answerPendingCreation &&
                    !state.answerPendingCreation.addedAsPersonalized ? (
                      <Answer
                        {...state.answerPendingCreation.data}
                        summaryQuestion={state.sampleQuestion}
                        understandingId={understandingId}
                      />
                    ) : (
                      <KBResponseWrite
                        workflowSteps={[]}
                        className={state.actionInProgress ? 'opacity-50' : ''}
                        approved={false}
                        initialAnswer=""
                        selectedFilter={undefined}
                        onSave={(req, moveToTop) =>
                          handleCreation(req, moveToTop, false)
                        }
                        addingPersonalizedResponse={false}
                        dialogId={null}
                        dialogName={null}
                        onCancel={() =>
                          actions.handleUpdateAddingDefault(false)
                        }
                        onDelete={undefined}
                        genAIProps={{
                          summaryQuestion: state.sampleQuestion,
                          understandingId,
                        }}
                      />
                    )}
                  </div>
                ) : null}
                <AddResponseButton
                  innerRef={animationRefs.defaultResponseButton}
                  className="mb-4"
                  disabled={
                    state.addingDefaultResponse ||
                    state.addingPersonalizedResponse
                  }
                  onClick={() => {
                    actions.handleUpdateAddingDefault(true)
                  }}
                />
                {state.suggestedAnswers.length > 0 ? (
                  <SuggestedAnswersDropdown
                    suggestedAnswers={state.suggestedAnswers}
                    copyAllAnswers={actions.handleCopyAllAnswers}
                    copyAnswer={actions.handleCopyAnswer}
                    defaultExpanded={unpersonalizedAnswers.length === 0}
                  />
                ) : null}
              </CollapsibleSection>
              <CollapsibleSection
                title="Personalized Responses"
                headerRef={animationRefs.personalizedTitleRef}
                info={personalizedHelpMessage}
                startExpanded={potentialNewQuestion === undefined}
                headerChildren={
                  <Tag color="blue-grey-050">
                    {sortedPersonalizedAnswers.length}
                  </Tag>
                }>
                <RefetchingOverlay enabled={state.reordering}>
                  <DragDropContext
                    onDragStart={() => actions.handleSetAnimationEnabled(false)}
                    onDragEnd={result => {
                      if (
                        !result.destination ||
                        result.destination.droppableId !==
                          'personalizedAnswersList'
                      ) {
                        return
                      }
                      // Move the dragged item to it's desired location in the list
                      sortedPersonalizedAnswers.splice(
                        result.destination.index,
                        0,
                        sortedPersonalizedAnswers.splice(
                          result.source.index,
                          1
                        )[0]
                      )
                      // Send new sorting to backend
                      actions.handleReorderingAnswers(
                        sortedPersonalizedAnswers.map(answer => answer.id)
                      )
                    }}>
                    <Droppable droppableId="personalizedAnswersList">
                      {provided => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {sortedPersonalizedAnswers.map((x, i) => {
                            return (
                              <Draggable
                                key={x.id}
                                draggableId={x.id.toString()}
                                index={i}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}>
                                    {mapAnswerToComponent(
                                      x,
                                      provided.dragHandleProps,
                                      snapshot.isDragging
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </RefetchingOverlay>
                {state.addingPersonalizedResponse ? (
                  <div ref={state.newAnswerRef}>
                    {state.answerPendingCreation &&
                    state.answerPendingCreation.addedAsPersonalized ? (
                      <Answer
                        {...state.answerPendingCreation.data}
                        summaryQuestion={state.sampleQuestion}
                        understandingId={understandingId}
                      />
                    ) : (
                      <KBResponseWrite
                        workflowSteps={[]}
                        className={state.actionInProgress ? 'opacity-50' : ''}
                        approved={false}
                        initialAnswer=""
                        dialogId={null}
                        dialogName={null}
                        selectedFilter={undefined}
                        addingPersonalizedResponse={true}
                        onSave={(req, moveToTop) =>
                          handleCreation(req, moveToTop, true)
                        }
                        onDelete={undefined}
                        onCancel={() => {
                          actions.handleUpdateAddingPersonalized(false)
                        }}
                      />
                    )}
                  </div>
                ) : null}
                <AddResponseButton
                  innerRef={animationRefs.personalResponseButton}
                  className="mb-4"
                  disabled={
                    state.addingDefaultResponse ||
                    state.addingPersonalizedResponse
                  }
                  onClick={() => {
                    actions.handleUpdateAddingPersonalized(true)
                  }}
                />
              </CollapsibleSection>
            </>
          </AnimateRefs>
          <div>
            <CollapsibleSection
              title="Fuzzy Questions"
              className="mb-2"
              headerRef={animationRefs.otherPanelContent}
              info="Fuzzy questions are alternative ways of asking the summary question. They help the bot know when two different questions are asking the same thing."
              headerChildren={
                <Tag color="blue-grey-050">
                  {state.institutionQuestions.length}
                </Tag>
              }>
              <div>
                {showPotentialNewQuestionUI && (
                  <div className="mb-3">
                    <div style={{ width: '456px' }}>
                      Add this message as a fuzzy question to improve matching.
                      We recommend questions to be less than 160 characters
                    </div>

                    <div className="d-flex mt-2">
                      <LabeledTextArea
                        label="Fuzzy Questions"
                        className={classNames(
                          'mb-0 d-flex align-items-center fuzzy-question-container'
                        )}
                        rows={2}
                        textAreaClassName="w-100 h-100"
                        overrideTextAreaMargin={true}
                        value={potentialNewQuestionFormValue}
                        onChange={e =>
                          setPotentialNewQuestionFormValue(e.target.value)
                        }
                      />
                    </div>

                    <Button
                      className="mt-3 d-flex align-items-center bg-white border-secondary-teal text-secondary-teal"
                      onClick={handleAddNewPotentialQuestion}
                      disabled={potentialNewQuestionCreateLoading}>
                      {(!potentialNewQuestionCreateLoading && (
                        <>
                          {/* Normal create button */}
                          <AHIcon
                            overrideWidth={true}
                            name="add_circle_outline"
                          />
                          <div className="ml-2">Add</div>
                        </>
                      )) || (
                        <>
                          {/* Show loading state after create button is pressed */}
                          <Spinner
                            size="sm"
                            className="stroke-secondary-teal"
                          />
                          <div className="ml-2">Creating</div>
                        </>
                      )}
                    </Button>
                  </div>
                )}

                {(showPotentialNewQuestionUI &&
                  potentialNewQuestionOtherHidden && (
                    <ContainerButton
                      className="mt-1 text-secondary-teal"
                      onClick={() => setPotentialNewQuestionOtherHidden(false)}>
                      Show All Questions
                    </ContainerButton>
                  )) || (
                  <>
                    {/* Show a HR seperator between the potential new question section and the rest of the "Questions" section content. */}
                    {showPotentialNewQuestionUI && (
                      <hr className="mt-2 mb-8 w-100" />
                    )}

                    {/* show matched question if it exists */}
                    {matchedQuestion && (
                      <>
                        <h6>Your bot matched to this fuzzy question</h6>
                        <p>{matchedQuestion}</p>
                        <hr className="mt-2 mb-8" />
                      </>
                    )}

                    <div className="mainstay-header-h5 text-mainstay-dark-blue-80">
                      Summary Question
                    </div>
                    <div className="mainstay-body-paragraph text-mainstay-dark-blue-80">
                      {state.sampleQuestion}
                    </div>
                    <hr className="my-4" />
                    <OrgSpecificQuestionsSection
                      loadingGenerated={loadingGeneratedQuestions}
                      understandingId={understandingId}
                      questions={state.institutionQuestions}
                      addQuestion={actions.updateInstitutionQuestion}
                      deleteQuestion={actions.deleteInstitutionQuestion}
                      updateOrgQuestion={actions.updateInstitutionQuestion}
                    />
                    {hasFeature(FeaturesType.GENERATIVE_AI_FUZZY_QUESTIONS) && (
                      <AIMenu
                        size={MenuSize.WIDE}
                        actions={[
                          {
                            action: AIAction.WRITE_QUESTIONS,
                            onClick: () => {
                              actions.addGeneratedInstitutionQuestions()
                            },
                            text: 'Generate 10 Questions',
                          },
                        ]}
                        eventTrackerObj={{
                          eventLocation: 'knowledge base',
                          eventAction: 'click',
                          eventObject: 'generate fuzzy questions',
                        }}
                      />
                    )}
                    <hr ref={animationRefs.hrRef} className="my-4" />
                    {/* Hide AdmitHub Questions section when we have none. */}
                    {state.enabledGlobalQuestions.length +
                      state.disabledGlobalQuestions.length >
                    0 ? (
                      <>
                        <div className="mb-0 mainstay-header-h5 text-mainstay-dark-blue-80">
                          Global Questions
                        </div>
                        <small className="mb-3 d-block text-muted">
                          If any question is not applicable to your
                          organization, deactivate it by clicking the toggle.
                        </small>
                        <FuzzyQuestionListSection
                          label="Global Collection"
                          questions={sortBy(
                            state.enabledGlobalQuestions,
                            x => x.id
                          )}
                          updateQuestion={actions.updateGlobalQuestion}
                          global
                        />
                        {state.disabledGlobalQuestions.length > 0 ? (
                          <FuzzyQuestionListSection
                            label="Global Collection"
                            questions={sortBy(
                              state.disabledGlobalQuestions,
                              x => x.id
                            )}
                            updateQuestion={actions.updateGlobalQuestion}
                            global
                          />
                        ) : null}
                      </>
                    ) : null}
                  </>
                )}
              </div>
            </CollapsibleSection>
          </div>
        </div>
      )}
    </>
  )
}

const DT_FORMAT = 'MM/DD/YYYY, hh:mmA z'

function QuestionEmbeddingStatus({
  metadata,
  questionCreated,
  questionModified,
  questionId,
  globalQuestionEnabled,
}: {
  questionId: number
  metadata?: IQuestionMetadata
  questionCreated?: string
  questionModified?: string
  globalQuestionEnabled?: boolean
}) {
  const timeZone = useSelector(getInstitutionTimeZone)
  if (!questionCreated || !questionModified) {
    return <></>
  }
  const questionModifiedAt = moment.tz(questionModified, timeZone)
  const questionCreatedAt = moment.tz(questionCreated, timeZone)
  if (!metadata) {
    return (
      <Tooltip
        interactive
        content={
          <div>
            <p className="caption font-weight-bold">
              Question {questionId} is not embedded
            </p>
            <p className="caption text-muted">
              Edited: <b>{questionModifiedAt.format(DT_FORMAT)}</b>
            </p>
            <p className="caption text-muted">
              Created: <b>{questionCreatedAt.format(DT_FORMAT)}</b>
            </p>
          </div>
        }>
        <AHIcon
          name="help"
          style={{
            height: '29px',
            color: globalQuestionEnabled
              ? 'red'
              : scssVariables.mainstayDisabledGray,
          }}
          className=""
        />
      </Tooltip>
    )
  }

  const questionLastEmbeddedAt = moment.tz(metadata.modified, timeZone)
  const questionFirstEmbeddedAt = moment.tz(metadata.created, timeZone)

  if (questionModifiedAt.diff(questionLastEmbeddedAt, 'minutes') > 5) {
    return (
      <Tooltip
        interactive
        content={
          <div>
            <p className="caption text-muted font-weight-bold">
              Question {questionId} embedding may be out of sync
            </p>
            <p className="caption text-muted">
              Edited: <b>{questionModifiedAt.format(DT_FORMAT)}</b>
            </p>
            <p className="caption text-muted">
              Last Embedded: <b>{questionLastEmbeddedAt.format(DT_FORMAT)}</b>
            </p>
            <p className="caption text-muted">
              Created: <b>{questionCreatedAt.format(DT_FORMAT)}</b>
            </p>
            <p className="caption text-muted">
              First embedded: <b>{questionFirstEmbeddedAt.format(DT_FORMAT)}</b>
            </p>
            <p className="caption text-muted">
              Pinecone ID: <b>{metadata.external_id}</b>
            </p>
          </div>
        }>
        <AHIcon
          name="help"
          style={{ height: '29px', color: 'gold' }}
          className=""
        />
      </Tooltip>
    )
  }

  return (
    <Tooltip
      interactive
      content={
        <div>
          <p className="caption text-muted font-weight-bold">
            Question {questionId} is embedded
          </p>
          <p className="caption text-muted">
            Edited: <b>{questionModifiedAt.format(DT_FORMAT)}</b>
          </p>
          <p className="caption text-muted">
            Last Embedded: <b>{questionLastEmbeddedAt.format(DT_FORMAT)}</b>
          </p>
          <p className="caption text-muted">
            Created: <b>{questionCreatedAt.format(DT_FORMAT)}</b>
          </p>
          <p className="caption text-muted">
            First embedded: <b>{questionFirstEmbeddedAt.format(DT_FORMAT)}</b>
          </p>
          <p className="caption text-muted">
            Pinecone ID: <b>{metadata.external_id}</b>
          </p>
        </div>
      }>
      <AHIcon
        name="help"
        style={{ height: '29px', color: scssVariables.mainstaySuccess600 }}
        className=""
      />
    </Tooltip>
  )
}

const mapStateToProps = (
  state: IState,
  ownProps: Pick<
    IKnowledgeSeedEditPanelContentProps,
    'understandingId' | 'onClose'
  >
) => {
  return {
    ...ownProps,
    mappingResults: getAttributeByIdMapping(state),
  }
}

export const KnowledgeSeedEditPanelContent = connect(mapStateToProps)(
  UnconnectedKnowledgeSeedEditPanelContent
)
