import * as React from 'react'
import SideEffectNode from 'components/SideEffectNode/SideEffectNode'
import {
  IExitAction,
  ICampaignScriptStep,
  ExitActionType,
  IRagtimeActionArgs,
} from 'store/campaign-scripts/reducer'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Formik, FormikProps } from 'formik'
import { Button } from 'components/Button/Button'
import * as nope from 'yup'
import { OutsideClickHandler } from 'components/OutsideClickHandler/OutsideClickHandler'
import { VerticalLine } from 'components/NodeSubtree/NodeSubtree'
import Select from 'components/Select/SelectV2'
import { LabeledTextInput } from 'components/LabeledInput/LabeledInput'

// TODO (ragtime-todos) actually fetch from GenAI topics in the component
const FAKE_OPTIONS = [
  { label: 'Career Coach', value: 1 },
  { label: 'Dog Training', value: 2 },
  { label: 'Animaniacs Characters', value: 3 },
  { label: 'Post-Hardcore Harsh Noise Acts', value: 4 },
]

interface IRagtimeActionProps {
  step: ICampaignScriptStep
  action: IExitAction<IRagtimeActionArgs>
  actionIndex: number
  editable: boolean
  onCancel: () => void
  editMode: boolean
  setEditMode: (val: boolean) => void
  onUpdateNode: (
    data: Partial<ICampaignScriptStep>,
    stayInEditMode?: boolean
  ) => void
}

export const RagTimeAction = ({
  editMode,
  action,
  step,
  editable,
  setEditMode,
  onCancel,
  onUpdateNode,
  actionIndex,
}: IRagtimeActionProps) => {
  const handleDeleteAction = React.useCallback(() => {
    const newExitActions = (step.exitActions || []).filter(
      (_, i: number) => i !== actionIndex
    )
    onUpdateNode({ exitActions: newExitActions })
  }, [actionIndex, onUpdateNode, step.exitActions])

  const label = React.useMemo(() => {
    const topicName = `${
      FAKE_OPTIONS.find(o => o.value === action.args.genAiTopicId)?.label
    }`
    return `Enter Gen-AI Discussion: ${topicName} (${action.args.maxMessages} Responses or ${action.args.maxMinutes} Minutes)`
  }, [action])

  const handleClick = React.useCallback(() => setEditMode(true), [setEditMode])

  const handleUpdateAction = React.useCallback(
    (updatedAction: IExitAction<IRagtimeActionArgs>) => {
      const newExitActions = step.exitActions || []
      newExitActions[actionIndex] = { ...updatedAction, newlyCreated: false }
      onUpdateNode({ exitActions: newExitActions })
    },
    [actionIndex, onUpdateNode, step.exitActions]
  )

  if (editMode || action.newlyCreated) {
    return (
      <div>
        <RagtimeActionForm
          action={action}
          onDeleteAction={handleDeleteAction}
          onCancel={onCancel}
          onUpdateAction={handleUpdateAction}
        />
      </div>
    )
  } else {
    return (
      <div>
        <VerticalLine />
        <SideEffectNode editable={editable} onClick={handleClick}>
          {label}
        </SideEffectNode>
      </div>
    )
  }
}

const validationSchema = nope.object().shape({
  genAiTopicId: nope
    .number()
    .required()
    .moreThan(0),
  maxMinutes: nope
    .number()
    .required()
    .moreThan(0, 'Duration must be between 1 and 1440 minutes (one day)')
    .lessThan(1441, 'Duration must be between 1 and 1440 minutes (one day)'),
  maxMessages: nope
    .number()
    .required()
    .moreThan(0, 'Maximum responses must be between 1 and 20')
    .lessThan(21, 'Maximum responses must be between 1 and 20'),
})

interface IRagtimeActionForm {
  genAiTopicId: number
  maxMinutes: number
  maxMessages: number
}

interface IRagtimeActionFormProps {
  action: IExitAction<IRagtimeActionArgs>
  onCancel: () => void
  onDeleteAction: () => void
  onUpdateAction: (updatedAction: IExitAction<IRagtimeActionArgs>) => void
}

const RagtimeActionForm = ({
  action,
  onCancel,
  onUpdateAction,
  onDeleteAction,
}: IRagtimeActionFormProps) => {
  return (
    <Formik<IRagtimeActionForm>
      validationSchema={validationSchema}
      initialValues={action.args}
      onSubmit={(formValues: IRagtimeActionForm) => {
        onUpdateAction({ name: ExitActionType.Ragtime, args: formValues })
      }}>
      {(form: FormikProps<IRagtimeActionForm>) => (
        <OutsideClickHandler
          excludeClassname="modal"
          containerClassName="border rounded background-white w-500px shadow-md mt-3"
          onClickOutside={() => form.handleSubmit()}>
          <div className="border rounded background-white">
            <div className="px-4 pt-4">
              <div className="d-flex justify-content-between">
                <h6>Enter Gen-AI Discussion</h6>
                <AHIcon
                  name="delete"
                  onClick={onDeleteAction}
                  className="pointer opacity-50 hover-opacity-100 hover-text-new-ui-danger"
                />
              </div>
            </div>
            <form onSubmit={form.handleSubmit}>
              <div className="p-4 pt-2">
                <p className="caption mt-2 mb-1">Select a topic</p>
                <Select<{ label: string; value: number }>
                  error={!!form.errors.genAiTopicId}
                  options={FAKE_OPTIONS}
                  value={
                    FAKE_OPTIONS.find(
                      option => option.value === form.values.genAiTopicId
                    ) || FAKE_OPTIONS[0]
                  }
                  onChange={option => {
                    if (option && !Array.isArray(option)) {
                      form.setFieldValue('genAiTopicId', option.value)
                    }
                  }}
                />
                {form.errors.genAiTopicId && (
                  <div className="text-danger small">
                    {form.errors.genAiTopicId}
                  </div>
                )}
                <p className="caption mt-3 mb-1">Exit after</p>
                <LabeledTextInput
                  value={form.values.maxMessages.toString()}
                  error={!!form.errors.maxMessages}
                  label="Responses"
                  type="number"
                  className="mb-0"
                  onChange={e => {
                    form.setFieldValue(
                      'maxMessages',
                      parseInt(e.target.value, 10)
                    )
                  }}
                />
                {form.errors.maxMessages && (
                  <div className="text-danger small">
                    {form.errors.maxMessages}
                  </div>
                )}
                <p className="caption mb-1">or</p>
                <LabeledTextInput
                  value={form.values.maxMinutes.toString()}
                  error={!!form.errors.maxMinutes}
                  label="Minutes"
                  type="number"
                  className="mb-0"
                  onChange={e => {
                    form.setFieldValue(
                      'maxMinutes',
                      parseInt(e.target.value, 10)
                    )
                  }}
                />
                {form.errors.maxMinutes && (
                  <div className="text-danger small">
                    {form.errors.maxMinutes}
                  </div>
                )}
              </div>
              <hr className="w-100 m-0 mt-2" />
              <div className="px-4 py-3">
                <Button
                  className="mr-2 btn btn-primary bg-secondary-teal"
                  disabled={Object.keys(form.errors).length > 0}
                  type="submit">
                  Save
                </Button>
                <Button
                  onClick={onCancel}
                  className="text-blue-grey-501 text-decoration-none"
                  color="link">
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </OutsideClickHandler>
      )}
    </Formik>
  )
}
